import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BigInt } from 'global';

import { toast } from "react-toastify";
import { useContractRead, useNetwork, useSwitchNetwork } from 'wagmi'
import { useDisconnect, useContractWrite } from 'wagmi'

import cross from "../../assects/images/cross.png"
import Bronze from "../../assects/images/bronze.png"
import Silver from "../../assects/images/silver.png"
import Gold from "../../assects/images/gold.png"
import benefits from "../../assects/icons/nfticon.png"
import Platinum from "../../assects/images/platinum.png"
import Crown from "../../assects/images/crown.png"
import c1 from "../../assects/images/c1.png"
import c2 from "../../assects/images/c2.png"
import c3 from "../../assects/images/c3.png"
import c4 from "../../assects/images/c4.png"
import c5 from "../../assects/images/c5.png"
import dd from "../../assects/images/dd.png"

import { bsc } from 'wagmi/chains'
const casinoProfits = [
    0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275,
    0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275,
    0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275,
    0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275,
    0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275,
    0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275,
    0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
]
const casinoProfits2 = [

    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
]
const stakeAndClaimContract = "0x255b82eC452E00e3E9D2c56441AB133B616704F8";
const scorpAddress = "0xa910A46E2F2002Fa9B5aA85F35b9440F6DAC4b10";
const MyWallet = ({ accounts }) => {
    const { t, i18n } = useTranslation()

    const { chain, chains } = useNetwork()
    const { switchNetwork } =
        useSwitchNetwork()


    const [claimStake, setClaimStake] = useState(null);
    const { data: userContribution } = useContractRead({
        address: stakeAndClaimContract,
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_userAddress",
                    "type": "address"
                }
            ],
            "name": "getUserContribiton",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getUserContribiton',
        chainId: 56,
        args: [accounts],
    })

    const { data: getClaimTokensData, write: getClaimTokens } = useContractWrite({
        address: stakeAndClaimContract,
        abi: [{
            "inputs": [],
            "name": "claimTokens",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }],
        functionName: 'claimTokens',
        chainId: bsc.id
    })


    const { data: userClaimed } = useContractRead({
        address: '0x316ff8185a7356dfa00309f88d203c87a20f64c0',
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "userClaimedUSDT",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'userClaimedUSDT',
        chainId: 56,
        args: [accounts],
    })

    const { data: userClaimedETH } = useContractRead({
        address: '0x3F5C9557dB581Fb853AC541C171766c3E77C3e06',
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "userClaimedUSDT",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'userClaimedUSDT',
        chainId: 1,
        args: [accounts],
    })

    const { data: getUSDTData, write: getUSDT } = useContractWrite({
        address: '0x316ff8185a7356dfa00309f88d203c87a20f64c0',
        abi: [{
            "inputs": [],
            "name": "getUSDT",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }],
        functionName: 'getUSDT',
        chainId: bsc.id
    })

    const { data: getUSDTDataETH, write: getUSDTETH } = useContractWrite({
        address: '0x3F5C9557dB581Fb853AC541C171766c3E77C3e06',
        abi: [{
            "inputs": [],
            "name": "getUSDT",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }],
        functionName: 'getUSDT',
        chainId: 1
    })


    const { data: getStakeIdData, refetch: refreshGetStakeId } = useContractRead({
        address: stakeAndClaimContract,
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_staker",
                    "type": "address"
                }
            ],
            "name": "getStakeIds",
            "outputs": [
                {
                    "internalType": "uint256[]",
                    "name": "",
                    "type": "uint256[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getStakeIds',
        chainId: 56,
        args: [accounts],
    })

    const { data: stakeUserAmountsData, refetch: refetchStakeUserAmounts } = useContractRead({
        address: stakeAndClaimContract,
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "stakeUserAmounts",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'stakeUserAmounts',
        chainId: 56,
        args: [accounts],
    })
    const { data: whenClaimData, refetch: refreshWhenClaimData } = useContractRead({
        address: stakeAndClaimContract,
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "addrs",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "_stakeId",
                    "type": "uint256"
                }
            ],
            "name": "whenClaim",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'whenClaim',
        chainId: 56,
        args: [accounts, claimStake],
    })

    const { data: cdaysETH } = useContractRead({
        address: '0x3F5C9557dB581Fb853AC541C171766c3E77C3e06',
        abi: [
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "name": "userClaimTimes",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            }],
        functionName: 'userClaimTimes',
        chainId: 1,
        args: [accounts],
    })
    const handleUSDT = () => {
        if (chain?.id === 56) {
            getUSDT?.()
        }
        if (chain?.id === 1) {
            getUSDTETH?.()
        }
    }
    const handleClaim = () => {
        if (chain?.id === 56) {
            getClaimTokens?.()
        }
    }
    const [totalProfit, setTotalProfit] = useState(0);
    const [usdtProfit, setUsdtProfit] = useState(0);
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const disconnect = useDisconnect({
        onSuccess(data) {
            console.log('Success', data)
        },
        onSettled(data, error) {
            console.log('Settled', { data, error })
        },
        onError(error) {
            console.log('Error', error)
        },
        onMutate(args) {
            console.log('Mutate', args)
        },

    })

    const [nft, setNFT] = useState(0)
    const [nftData, setNFTData] = useState("No NFT")
    const [nextNFT, setNextNFT] = useState("Bronze")
    const [nextNftImage, setNextNftImage] = useState(c1)
    const [nextNftGoal, setNextNftGoal] = useState(1000)
    const [nftImage, setNFTImage] = useState(benefits)

    function truncateToDecimals(num, dec = 2) {
        const calcDec = 10 ** dec
        return Math.trunc(num * calcDec) / calcDec;
    }

    const handleRef = () => {
        toast.success("Copied Referral Link")
        let link = window.location.origin + "/?ref=" + accounts + "&lang=" + i18n.language
        navigator.clipboard.writeText(link);

    }
    const [firstClaim, setFirstClaim] = useState("No stake")
    const [totalStaked, setTotalStaked] = useState(0)
    const [finalUserTokens, setFinalUserTokens] = useState(0)
    const [finalBonusTokens, setFinalBonusTokens] = useState(0)
    const [finalUserContribution, setFinalUserContribution] = useState(0)
    const [claimDaysBNB, setClaimDaysBNB] = useState(0)
    const [claimDaysETH, setClaimDaysETH] = useState(0)

    const countDaysFromClaimBNB = (c) => {
        if (c !== 0) {
            var date = new Date(c * 1000);
            var resultFormat = date.toISOString().split('T')[0]
            const today = new Date();
            today.setHours(0, 0, 0, 0)
            const [year, month, day] = (resultFormat).split('-').map(Number);
            const launchDate = new Date(year, month - 1, day)
            const diff = today - launchDate;
            const res = Math.floor(diff / (1000 * 60 * 60 * 24));
            setClaimDaysBNB(res)
        }
        else
            setClaimDaysBNB(30)
    }
    const countDaysFromClaimETH = (c) => {
        if (c !== 0) {
            var date = new Date(c * 1000);
            var resultFormat = date.toISOString().split('T')[0]
            const today = new Date();
            today.setHours(0, 0, 0, 0)
            const [year, month, day] = (resultFormat).split('-').map(Number);
            const launchDate = new Date(year, month - 1, day)
            const diff = today - launchDate;
            const res = Math.floor(diff / (1000 * 60 * 60 * 24));
            setClaimDaysETH(res)
        }
        else
            setClaimDaysETH(30)

    }
    const countDays = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0)
        const [year, month, day] = ("2023-07-10").split('-').map(Number);
        const launchDate = new Date(year, month - 1, day)
        const diff = today - launchDate;
        const res = Math.floor(diff / (1000 * 60 * 60 * 24));
        let y = 0;
        for (let x = 0; x < res; x++) {
            y += casinoProfits[x]
        }
        if (y > 0)
            setTotalProfit(y)
    }

    const countDays2 = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0)
        const [year, month, day] = ("2023-11-19").split('-').map(Number);
        const launchDate = new Date(year, month - 1, day)
        const diff = today - launchDate;
        const res = Math.floor(diff / (1000 * 60 * 60 * 24));
        let y = 0;
        for (let x = 0; x < res; x++) {
            y += casinoProfits2[x]
        }
        setUsdtProfit(y)
    }

    const { data: userTokens, isSuccess: successUserTokens } = useContractRead({
        address: stakeAndClaimContract,
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_userAddress",
                    "type": "address"
                }
            ],
            "name": "getUserTokens",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getUserTokens',
        chainId: 56,
        args: [accounts],
    })


    const { data: userBonus, isSuccess: successUserBonusTokens } = useContractRead({
        address: stakeAndClaimContract,
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_userAddress",
                    "type": "address"
                }
            ],
            "name": "getUserBonusTokens",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getUserBonusTokens',
        chainId: 56,
        args: [accounts],
    })

    //GETSTAKEID
    const handleTest2 = async (e) => {

        let ar = await refreshGetStakeId()
        setClaimStake(Number(getStakeIdData[0]))

    }
    function convertSecondsToDate(seconds) {
        // Convert seconds to milliseconds
        const milliseconds = seconds * 1000;

        // Create a new Date object with the milliseconds
        const date = new Date(milliseconds);

        setFirstClaim(date.toDateString());
    }
    useEffect(() => {
        if (getStakeIdData)
            setClaimStake(Number(getStakeIdData[0]))

    }, [successUserBonusTokens, successUserTokens])

    useEffect(() => {

        if (whenClaimData)
            convertSecondsToDate(Number(whenClaimData))
        if (stakeUserAmountsData)
            setTotalStaked(Number(stakeUserAmountsData) / 1e18)

    }, [claimStake])

    useEffect(() => {
        countDays()
        countDays2()
        let claimBNB = 1700496000
        let claimETH = 1700496000
        if (claimBNB)
            countDaysFromClaimBNB(claimBNB)
        if (claimETH)
            countDaysFromClaimETH(claimETH)
        setFinalUserTokens(Number(userTokens) / 1e18)

        setFinalBonusTokens(Number(userBonus) / 1e18)
        setFinalUserContribution(Number(userContribution) / 1e6)

    }, [successUserBonusTokens, successUserTokens])

    useEffect(() => {
        if (finalUserContribution >= 1000) {
            setNFT(0.1)
            setNFTData("Bronze")
            setNFTImage(Bronze)
            setNextNFT("Silver")
            setNextNftGoal(5000)
            setNextNftImage(c2)
        }
        if (finalUserContribution >= 5000) {
            setNFT(0.15)
            setNFTData("Silver")
            setNFTImage(Silver)
            setNextNFT("Gold")
            setNextNftGoal(10000)
            setNextNftImage(c3)
        }
        if (finalUserContribution >= 10000) {
            setNFT(0.25)
            setNFTData("Gold")
            setNFTImage(Gold)
            setNextNFT("Platinum")
            setNextNftGoal(25000)
            setNextNftImage(c4)
        }
        if (finalUserContribution >= 25000) {
            setNFT(0.4)
            setNFTData("Platinum")
            setNFTImage(Platinum)
            setNextNftGoal(100000)
            setNextNFT("Diamond")
            setNextNftImage(c5)
        }
        if (finalUserContribution >= 100000) {
            setNFT(0.6)
            setNFTData("Diamond")
            setNFTImage(Crown)
        }

    }, [finalUserContribution])

    const [usdtMenu, setUsdtMenu] = useState(false)
    const [stakingMenu, setStakingMenu] = useState(false)
    const handleClose = () => {
        setUsdtMenu(!usdtMenu)
    }
    const handleStakingClose = () => {
        setStakingMenu(!stakingMenu)
    }
    return (
        <>

            <div
                style={{
                    borderRadius: "10px",
                    display: "block"
                }}>
                <div
                    style={{
                        height: "90px",
                        display: "block",
                        border: "4px",
                        borderStyle: "solid",
                        borderColor: "#f9d548",
                        borderRadius: "10px",
                    }}>
                    <div style={{
                        fontWeight: "bold",
                        lineHeight: "2",
                        fontSize: "15px",
                        color: "#fff",
                        border: "2px",
                        borderRadius: "10px",
                        padding: "10px 20px 20px 20px",
                        textAlign: "center"
                    }}>
                        {("Launch Price")}
                        <div>
                            1 SCORP = $0.05
                        </div>
                    </div>
                </div>
                <div style={{
                    fontWeight: "bold",
                    lineHeight: "2",
                    fontSize: "18px",
                    border: "4px",
                    borderStyle: "solid",
                    borderColor: "#f9d548",
                    borderRadius: "10px",
                    padding: "20px",
                    marginTop: "10px",
                    textAlign: "center",
                    height: "100%",
                    // height: "480px",
                    backgroundColor: "black",
                    color: "white"
                }}>
                    {`${month[new Date().getMonth()]} ${new Date().getDate()} ,  ${new Date().getFullYear()}`}
                    <div style={{ display: "grid", fontSize: "13px", marginTop: "13px" }}>
                        <div style={{ display: "flex", alignItems: "center", fontSize: "13px", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src="/logo.png" alt='logo' width="30px" height="30px" style={{ marginRight: "6px" }} />

                                <span style={{ justifySelf: "center" }}>SCORP</span>
                            </div>
                            <span style={{ justifySelf: "right" }}>{finalUserTokens.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </span>
                        </div>
                    </div>


                    <div style={{ display: "grid", fontSize: "13px", marginTop: "13px" }}>
                        <div style={{ display: "flex", alignItems: "center", fontSize: "13px", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src="/casino.png" alt='logo' width="30px" height="30px" style={{ marginRight: "6px" }} />

                                <span style={{ justifySelf: "center" }}>Bonus</span>
                            </div>
                            <span style={{ justifySelf: "right" }}>{(finalBonusTokens + (finalUserTokens * nft)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </span>
                        </div>
                    </div>

                    <div style={{ display: "grid", fontSize: "13px", marginTop: "10px" }}>
                        <div style={{ display: "flex", alignItems: "center", fontSize: "13px", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src="/golddollar.png" alt='logo' width="30px" height="30px" style={{ marginRight: "6px" }} />

                                <span style={{ justifySelf: "center" }}>{("Staking Rewards")}</span>
                            </div>
                            <span style={{ justifySelf: "right" }}>{(finalUserTokens * (totalProfit)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            {/* 0.875% / Day in SCORP  */}
                        </div>
                    </div>
                    <div style={{ display: "grid", fontSize: "13px", marginTop: "10px" }} onClick={handleStakingClose}>
                        <div style={{ display: "flex", alignItems: "center", fontSize: "13px", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src="/golddollar.png" alt='logo' width="30px" height="30px" style={{ marginRight: "6px" }} />

                                <span style={{ justifySelf: "center" }}>{("Staking Pool")}</span>
                                <img src={dd} alt='logo' width="20px" height="20px" style={{ marginRight: "6px", padding: "3px" }} />
                            </div>
                            <span style={{ justifySelf: "right" }}>{(totalStaked).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            {/* 0.875% / Day in SCORP  */}
                        </div>
                    </div>



                    {
                        stakingMenu &&
                        <div>
                            <div style={{ display: "grid", fontSize: "10px", marginTop: "5px", marginLeft: "35px" }}>
                                <div style={{ display: "flex", alignItems: "center", fontSize: "10px", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <span style={{ justifySelf: "center" }}>{("Staked")}</span>
                                    </div>

                                    <span style={{ justifySelf: "right" }}>{(totalStaked).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </span>

                                </div>
                            </div>

                            <div style={{ display: "grid", fontSize: "10px", marginTop: "5px", marginLeft: "35px" }}>
                                <div style={{ display: "flex", alignItems: "center", fontSize: "10px", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <span style={{ justifySelf: "center" }}>{("Earned")}</span>
                                    </div>
                                    0
                                </div>
                            </div>

                            <div style={{ display: "grid", fontSize: "10px", marginTop: "5px", marginLeft: "35px" }}>
                                <div style={{ display: "flex", alignItems: "center", fontSize: "10px", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <span style={{ justifySelf: "center" }}>{("Total")}</span>
                                    </div>
                                    {totalStaked}
                                </div>
                            </div>

                            <div style={{ display: "grid", fontSize: "10px", marginTop: "5px", marginLeft: "35px" }}>
                                <div style={{ display: "flex", alignItems: "center", fontSize: "10px", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <span style={{ justifySelf: "center" }}>{("Lock Up Period")}</span>
                                    </div>
                                    {firstClaim}
                                </div>
                            </div>
                        </div>
                    }
                    <div style={{ display: "grid", fontSize: "13px", marginTop: "10px" }} onClick={handleClose}>
                        <div style={{ display: "flex", alignItems: "center", fontSize: "13px", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src="/USDT.png" alt='logo' width="30px" height="30px" style={{ marginRight: "6px", padding: "3px" }} />

                                <span style={{ justifySelf: "center" }}>{("USDT Rewards")}</span>
                                <img src={dd} alt='logo' width="20px" height="20px" style={{ marginRight: "6px", padding: "3px" }} />
                            </div>

                            {

                                finalUserContribution < 10000 &&
                                0


                            }
                            {
                                finalUserContribution >= 10000 && finalUserContribution <= 24999 &&
                                <span style={{ justifySelf: "right" }}>{((((finalUserTokens + finalBonusTokens) * (Number(usdtProfit))) * .1 * .05)).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}
                                </span>
                            }

                            {
                                finalUserContribution >= 25000 && finalUserContribution <= 99999 &&
                                <span style={{ justifySelf: "right" }}>{((((finalUserTokens + finalBonusTokens) * (Number(usdtProfit))) * .15 * .05)).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}
                                </span>
                            }

                            {
                                finalUserContribution >= 100000 &&
                                <span style={{ justifySelf: "right" }}>{((((finalUserTokens + finalBonusTokens) * (Number(usdtProfit))) * .2 * .05)).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}
                                </span>
                            }
                        </div>
                    </div>


                    {
                        usdtMenu &&
                        <div>
                            <div style={{ display: "grid", fontSize: "10px", marginTop: "5px", marginLeft: "35px" }}>
                                <div style={{ display: "flex", alignItems: "center", fontSize: "10px", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <span style={{ justifySelf: "center" }}>{("Claimed")}</span>
                                    </div>

                                    <span style={{ justifySelf: "right" }}>{(Number(userClaimed) / 1e18 + Number(userClaimedETH) / 1e6).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </span>

                                </div>
                            </div>

                            <div style={{ display: "grid", fontSize: "10px", marginTop: "5px", marginLeft: "35px" }}>
                                <div style={{ display: "flex", alignItems: "center", fontSize: "10px", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <span style={{ justifySelf: "center" }}>{("Staked")}</span>
                                    </div>

                                    {

                                        finalUserContribution < 10000 &&
                                        0
                                        // <span style={{ justifySelf: "right" }}>{(((((finalUserTokens + finalBonusTokens) * (Number(usdtProfit))) * .1 * .05)) - Number(userClaimed)/1e18).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}
                                        // </span>


                                    }
                                    {
                                        finalUserContribution >= 10000 && finalUserContribution <= 24999 &&
                                        <span style={{ justifySelf: "right" }}>{(((((finalUserTokens + finalBonusTokens) * (Number(usdtProfit))) * .1 * .05) - (Number(userClaimed) / 1e18 + Number(userClaimedETH) / 1e6))).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}                                        </span>
                                    }

                                    {
                                        finalUserContribution >= 25000 && finalUserContribution <= 99999 &&
                                        <span style={{ justifySelf: "right" }}>{(((((finalUserTokens + finalBonusTokens) * (Number(usdtProfit))) * .15 * .05) - (Number(userClaimed) / 1e18 + Number(userClaimedETH) / 1e6))).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}
                                        </span>
                                    }

                                    {
                                        finalUserContribution >= 100000 &&
                                        <span style={{ justifySelf: "right" }}>{(((((finalUserTokens + finalBonusTokens) * (Number(usdtProfit))) * .2 * .05) - (Number(userClaimed) / 1e18 + Number(userClaimedETH) / 1e6))).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    <div style={{ display: "grid", fontSize: "13px", marginTop: "13px" }}>
                        <div style={{ display: "flex", alignItems: "center", fontSize: "13px", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src={nftImage} alt='logo' width="30px" height="30px" style={{ marginRight: "6px" }} />

                                <span style={{ justifySelf: "center" }}>Scorpion NFT</span>
                            </div>
                            <span style={{ justifySelf: "right" }}>{nftData} </span>
                        </div>
                    </div>



                    {
                        finalUserContribution < 10000 ?
                            <div>
                                <button
                                    className="bg-[#919191] w-[100%] py-1  mt-6 text-[15px] rounded xs:text-[14px] font-[600] font-[Poppins] text-black"
                                    // type="button" onClick={handleUSDT}
                                    type="button" onClick={() => toast.error("You must have at least Gold Membership NFT to receive USDT rewards.")}
                                >
                                    {("Withdraw USDT")}
                                </button>
                            </div>
                            :

                            <div>


                                {claimDaysBNB > 29 ?

                                    <button
                                        className="bgcolor w-[100%] py-1  mt-6 text-[15px] rounded xs:text-[14px] font-[600] font-[Poppins] text-black"
                                        type="button" onClick={handleUSDT}

                                    // type="button" onClick={() => toast.error("You can withdraw USDT after 30 days.")}
                                    >

                                        {("Withdraw USDT")}
                                    </button> :
                                    <>
                                        {
                                            claimDaysETH > 29 ?

                                                <button
                                                    className="bgcolor w-[100%] py-1  mt-6 text-[15px] rounded xs:text-[14px] font-[600] font-[Poppins] text-black"
                                                    type="button" onClick={handleUSDT}
                                                // type="button" onClick={() => toast.error("You can withdraw USDT after 30 days.")}

                                                >
                                                    {("Withdraw USDT")}
                                                </button>
                                                : <>
                                                </>
                                        }
                                    </>
                                }



                            </div>
                    }
                    <div>
                        <button
                            className="bgcolor w-[100%] py-1  mt-2   text-[15px] rounded xs:text-[14px] font-[600] font-[Poppins] text-black"
                            type="button" onClick={handleClaim}>
                            {("Claim $SCORP")}
                        </button>
                    </div>

                    <div>
                        <button
                            className="bgcolor w-[100%] py-1 mt-2 text-[15px] rounded xs:text-[14px] font-[600] font-[Poppins] text-black"
                            type="button"
                            onClick={() => disconnect.disconnect()}>
                            {("Disconnect")}
                        </button>
                    </div>

                    {/* <div>
                        <button
                            className="bgcolor w-[100%] py-1 mt-2 text-[15px] rounded xs:text-[14px] font-[600] font-[Poppins] text-black"
                            type="button"
                            onClick={handleClaim}>
                            {("Claim Test")}
                        </button>
                    </div> */}
                </div>

            </div>

        </>
    )
}

export default MyWallet