import React from "react";
import { useTranslation } from "react-i18next";

import { ToastContainer, toast } from 'react-toastify';

import pancake from "../../assects/video/pancake.png";
import dextools from "../../assects/video/dextools.png";
import dex from "../../assects/video/dex.png";

import copyIcon from "../../assects/svgs/copy-icon-1.png.svg"
const imgData = [
    {
        id: 7,
        imgName: pancake,
        link: "https://pancakeswap.finance/swap?chain=eth&outputCurrency=0xa910a46e2f2002fa9b5aa85f35b9440f6dac4b10"
    },
    {
        id: 5,
        imgName: dextools,
        link: "https://www.dextools.io/app/en/bnb/pair-explorer/0xfe62c80ba14a94b3673764e2e80f09d0f4e2de4b?t=1713268922373"
    },
    {
        id: 3,
        imgName: dex,
        link: "https://www.dexview.com/bsc/0xa910A46E2F2002Fa9B5aA85F35b9440F6DAC4b10"
    },
]



const BuyScorp = () => {
    const { t } = useTranslation();
    const handleCopy = () => {
        navigator.clipboard.writeText("0xa910A46E2F2002Fa9B5aA85F35b9440F6DAC4b10");
        toast(" Contract Address Copied!");
    }

    return (
        <>
            <div className="w-full 2xl:px-44 xl:px-44 px-10  bg-[#151414] py-12" >
                <div className='py-[30px] xs:py-[20px] text-center'>
                    <h2 className="font-[800] font-[Poppins] color text-[34px] xs:text-[30px] gradientText uppercase">{t("$SCORP CONTRACT: ")}</h2>
                    <div className="flex  justify-center">
                        <h5 className='flex font-[Inter] text-center text-[22px] xs:text-[15px] font-[500] text-[#ffffff] font-bold mt-3'>
                            0xa910A46E2F2002Fa9B5aA85F35b9440F6DAC4b10
                            <img src={copyIcon} className="cursor-pointer ml-3" alt="" onClick={handleCopy} width={20} />
                            <ToastContainer />

                        </h5>
                    </div>
                </div>
                <div className="text-center">
                    <h2 className="font-[800] font-[Poppins] color text-[34px] xs:text-[30px] gradientText uppercase">{t("BUY $SCORP NOW")}</h2>
                </div>
                <div className="pt-5 grid grid-cols-3 lg:grid-cols-3 md:grid-cols-2 gap-2 xs:grid-cols-2 sm:grid-cols-2 w-full flex 2xl:justify-between xl:justify-between lg:justify-between md:justify-evenly sm:justify-evenly justify-evenly flex-wrap">
                    {
                        imgData.map((item, id) => (
                            <a href={item.link} key={id} className="justify-self-center	 md:flex md:justify-center sm:flex sm:justify-center xs:flex xs:justify-center">
                                <img className="items-center content-center" src={item.imgName} width={200} alt="" />
                            </a>
                        ))
                    }

                </div>
            </div>

        </>
    )
}
export default BuyScorp