import React from "react";
import { useTranslation } from "react-i18next";


import img1 from "../../assects/icons/fe (9).png"
import img2 from "../../assects/icons/fe (12).png"
import img3 from "../../assects/icons/fe (3).png"
import img4 from "../../assects/icons/fe (6).png"
import img5 from "../../assects/icons/fe (10).png"
import img6 from "../../assects/icons/fe (1).png"
import img7 from "../../assects/icons/fe (4).png"
import img8 from "../../assects/icons/fe (7).png"
import img9 from "../../assects/icons/fe (11).png"
import img10 from "../../assects/icons/fe (2).png"
import img11 from "../../assects/icons/fe (5).png"
import img12 from "../../assects/icons/fe (8).png"

import citypaper from "../../assects/images/citypaper.png"
import bitcoinist from "../../assects/images/company/bitcoinist.png"
import cryptonaute from "../../assects/images/company/cryptonaute.png"
import coinciege from "../../assects/images/company/coinciege.png"
import finanzen from "../../assects/images/company/finanzen.png"
import cryptonews from "../../assects/images/company/cryptonews.png"
import kryptoszene from "../../assects/images/company/kryptoszene.png"
import newsbtc from "../../assects/images/company/newsbtc.png"
import cmc from "../../assects/images/company/cmc.png";
import fin from "../../assects/images/company/fin.png";
import ben from "../../assects/images/company/ben.png";
import yahoo from "../../assects/images/company/yahoo-finance.png";
// const imgData = [

//     {
//         id: 1,
//         imgName: img1,
//     },
//     {
//         id: 2,
//         imgName: img2,
//     },
//     {
//         id: 3,
//         imgName: img3,
//     },
//     {
//         id: 4,
//         imgName: img4,
//     },
//     {
//         id: 5,
//         imgName: img5,
//     },
//     {
//         id: 6,
//         imgName: img6,
//     },
//     {
//         id: 7,
//         imgName: img7,
//     },
//     {
//         id: 8,
//         imgName: img8,
//     },
//     {
//         id: 9,
//         imgName: img9,
//     },
//     {
//         id: 10,
//         imgName: img10,
//     },
//     {
//         id: 11,
//         imgName: img11,
//     },
//     {
//         id: 12,
//         imgName: img12,
//     },
// ]


const imgData = [
    {
        id: 7,
        imgName: cmc,
        link: "https://coinmarketcap.com/community/articles/64dc81f962fbc7602bf862da/"
    },
    {
        id: 5,
        imgName: bitcoinist,
        link: "https://bitcoinist.com/scorpion-casino-presale-crosses-1m-despite-the-lukewarm-market-why-is-this-new-coin-trending/"
    },
    {
        id: 3,
        imgName: yahoo,
        link: "https://ca.finance.yahoo.com/news/scorpion-casino-building-number-1-154500256.html"
    },
    {
        id: 4,
        imgName: ben,
        link: "https://www.benzinga.com/markets/cryptocurrency/23/07/33167061/master-the-game-your-ultimate-guide-to-scorpion-token"
    }
]

const imgData2 = [
    {
        id: 2,
        imgName: cryptonaute,
        link: "https://cryptonaute.fr/rollbit-fait-bond-7000-2023-scorpion-casino-prochain-beneficier-action-prix-explosive/"
    },
    {
        id: 8,
        imgName: coinciege,
        link: "https://coincierge.de/2023/2-millionen-dollar-ziel-fuer-scorpion-casino-in-sicht-aktuelle-scorp-vorverkaufsphase-endet-in-2-tagen/"
    },
    {
        id: 6,
        imgName: finanzen,
        link: "https://www.finanzen.net/nachricht/devisen/scorpion-casino-ist-die-krypto-spielplattform-auf-die-gamer-anleger-gewartet-haben-12703620"
    },
    {
        id: 6,
        imgName: cryptonews,
        link: "https://cryptonews.com/news/3-reasons-why-you-should-buy-scorp-next-utility-token-100x.htm"
    },
]

const imgData3 = [
    {
        id: 2,
        imgName: kryptoszene,
        link: "https://kryptoszene.de/scorpion-casino-vorverkauf-ueberschreitet-1m-trotz-des-lauwarmen-marktes-warum-ist-dieser-neue-coin-im-trend/"
    },
    {
        id: 1,
        imgName: newsbtc,
        link: "https://kryptoszene.de/scorpion-casino-vorverkauf-ueberschreitet-1m-trotz-des-lauwarmen-marktes-warum-ist-dieser-neue-coin-im-trend/"
    },
    {
        id: 1,
        imgName: fin,
        link: "https://finbold.com/250k-giveaway-entry-40-casino-credits-and-daily-passive-income-scorp-presale-shouldnt-be-missed-this-month/"
    },
    {
        id: 1,
        imgName: citypaper,
        link: "https://washingtoncitypaper.com/article/619539/a-world-beyond-gambling-five-ways-to-earn-side-income-from-scorpion-casino/"
    },
]



const FeaturedSection = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="w-full 2xl:px-44 xl:px-44 px-10  bg-[#151414] py-12" >
                <div className="text-center">
                    <h2 className="font-[800] font-[Poppins] color text-[34px] xs:text-[30px] gradientText uppercase">{t("Featured In")}</h2>
                </div>
                <div className="pt-5 grid grid-cols-4 lg:grid-cols-4 md:grid-cols-2 gap-2 xs:grid-cols-2 sm:grid-cols-2 w-full flex 2xl:justify-between xl:justify-between lg:justify-between md:justify-evenly sm:justify-evenly justify-evenly flex-wrap">
                    {
                        imgData.map((item, id) => (
                            <a href={item.link} key={id} className="justify-self-center	 md:flex md:justify-center sm:flex sm:justify-center xs:flex xs:justify-center">
                                <img className="items-center content-center" src={item.imgName} width={200} alt="" />
                            </a>
                        ))
                    }

                </div>

                <div className="pt-5 grid grid-cols-4 lg:grid-cols-4 md:grid-cols-2 gap-2 xs:grid-cols-2 sm:grid-cols-2 w-full flex 2xl:justify-between xl:justify-between lg:justify-between md:justify-evenly sm:justify-evenly justify-evenly flex-wrap">
                    {
                        imgData2.map((item, id) => (
                            <a href={item.link} key={id} className="md:flex justify-self-center	md:justify-center sm:flex sm:justify-center xs:flex xs:justify-center">
                                <img className="items-center content-center" src={item.imgName} width={200} alt="" />
                            </a>
                        ))
                    }

                </div>
                <div className="pt-5 grid grid-cols-4 lg:grid-cols-4 md:grid-cols-2 gap-2 xs:grid-cols-2 sm:grid-cols-2 w-full flex 2xl:justify-between xl:justify-between lg:justify-between md:justify-evenly sm:justify-evenly justify-evenly flex-wrap">

                    {
                        imgData3.map((item, id) => (
                            <a href={item.link} key={id} className="md:flex justify-self-center	md:justify-center sm:flex sm:justify-center xs:flex xs:justify-center">
                                <img className="items-center content-center" src={item.imgName} width={200} alt="" />
                            </a>
                        ))
                    }

                </div>
            </div>

        </>
    )
}
export default FeaturedSection