import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../components/Button/button";
import Beta from "../../assects/images/beta.png"




const BiggestSection = () => {
    const { t } = useTranslation();

    const [show, setShow] = useState(false);
    const [showmob, setShowmob] = useState(false);

    const handelShowMore = () => {
        setShow(!show)
    }

    const handelShowMoremob = () => {
        setShowmob(!showmob)
    }
    // console.log("check", handelShowMoremob)
    return (
        <div>
            <div className="overflow-scroll scroll  2xl:block xl:block lg:block md:hidden sm:hidden hidden">
                <div className="Biggestbg relative z-[2] w-[100%] min-w-[900px]" >
                    <div className="flex items-center px-[29px] py-[40px] justify-between xs:block sm:block md:block">
                        <div className=" flex ">
                            <h3 className="w-[100%] max-w-[26%] text-[32px] gradientText uppercase font-[900] font-[Inter] leading-[45px] text-white border-r ">{t('BiggestSection.title')}</h3>
                            <p className="w-[80%] text-white text-[18px] font-[inter] pl-5 font-[400] leading-[28px]">{t('BiggestSection.para')}</p>
                        </div>
                    </div>
                    <div className="px-[20px] pb-[40px] 2xl:block xl:block lg:block md:hidden sm:hidden hidden space-y-3">
                        <div className="px-6">
                            <ul className="flex justify-between ">
                                <li className="text-[#F9C333] w-[120px]  text-[18px] font-[800] leading-[28px] font-[inter]" >{t('BiggestSection.rank')}</li>
                                <li className="text-[#F9C333] w-[180px] text-center  text-[18px] font-[800] leading-[28px] font-[inter]" >{t('BiggestSection.wallet')}</li>
                                <li className="text-[#F9C333] w-[180px] text-center  text-[18px] font-[800] leading-[28px] font-[inter]" >{t('BiggestSection.date')}</li>
                                <li className="text-[#F9C333] w-[180px] text-center  text-[18px] font-[800] leading-[28px] font-[inter]" >{t('BiggestSection.tx')}</li>
                                <li className="text-[#F9C333] w-[180px] text-center  text-[18px] font-[800] leading-[28px] font-[inter]" >{t('BiggestSection.totalBuy')}</li>
                                <li className="text-[#F9C333] w-[180px] text-center  text-[18px] font-[800] leading-[28px] font-[inter]" >{t('BiggestSection.nft')}</li>
                                <li className="text-[#F9C333] w-[120px] text-end  text-[18px] font-[800] leading-[28px] font-[inter]" >{t('BiggestSection.prize')}</li>
                            </ul>
                        </div>
                        <div className="px-6 tablerow">
                            <ul className="flex justify-between ">
                                <li className="text-[#FFF9AD] w-[120px]  py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >1st</li>
                                <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >0x0de...8e610</li>
                                <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >2024-03-18</li>
                                <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >1</li>
                                <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >$315,513.01</li>
                                <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >Diamond</li>
                                <li className="text-[#FFF9AD] w-[120px] text-end py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >$10,000</li>
                            </ul>
                        </div>
                        <div className="px-6 tablerow2">
                            <ul className="flex justify-between ">
                                <li className="text-[#FFF9AD] w-[120px]  py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >2nd</li>
                                <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >0x589...18e01</li>
                                <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >2023-07-27</li>
                                <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >5 </li>
                                <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >$201,181.17</li>
                                <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >Diamond</li>
                                <li className="text-[#FFF9AD] w-[120px] text-end py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >$5,000</li>
                            </ul>
                        </div>
                        <div className="px-6 tabalerow3">
                            <ul className="flex justify-between ">
                                <li className="text-[#FFF9AD] w-[120px]  py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >3rd</li>
                                <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >0xe6e...32b4f</li>
                                <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >2024-03-17</li>
                                <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >1</li>
                                <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >$108,999.00</li>
                                <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >Diamond</li>
                                <li className="text-[#FFF9AD] w-[120px] text-end py-2 text-[18px] font-[600] leading-[28px] font-[inter]" >$3,000</li>
                            </ul>
                        </div>
                        <div className="px-6 ">
                            <ul className="flex justify-between ">
                                <li className="text-[#fff] w-[120px]  py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >4th</li>
                                <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >0x9bd...6746c</li>
                                <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >2024-03-09</li>
                                <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >23</li>
                                <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >$106,799.20</li>
                                <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >Diamond</li>
                                <li className="text-[#fff] w-[120px] text-end py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >$2,000</li>
                            </ul>
                        </div>
                        <div className="px-6 ">
                            <ul className="flex justify-between ">
                                <li className="text-[#fff] w-[120px]  py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >5th</li>
                                <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >0xbbf...c627c</li>
                                <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >2024-03-05</li>
                                <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >2</li>
                                <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >$105,349.88</li>
                                <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >Diamond</li>
                                <li className="text-[#fff] w-[120px] text-end py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >$1500</li>
                            </ul>
                        </div>
                        {
                            show &&
                            <>
                                <div className="px-6 ">
                                    <ul className="flex justify-between ">
                                        <li className="text-[#fff] w-[120px]  py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >6th</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >0xcc6...85fda</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >2024-03-18</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >12</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >$105,120.76</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >Diamond</li>
                                        <li className="text-[#fff] w-[120px] text-end py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >$900</li>
                                    </ul>
                                </div>

                                <div className="px-6 ">
                                    <ul className="flex justify-between ">
                                        <li className="text-[#fff] w-[120px]  py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >7th</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >0x145...122eb</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >2023-12-05</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >5</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >$103,213.41</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >Diamond</li>
                                        <li className="text-[#fff] w-[120px] text-end py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >$800</li>
                                    </ul>
                                </div>

                                <div className="px-6 ">
                                    <ul className="flex justify-between ">
                                        <li className="text-[#fff] w-[120px]  py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >8th</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >0x815...7f652</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >2024-03-02</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >5</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >$103,160.51</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >Diamond</li>
                                        <li className="text-[#fff] w-[120px] text-end py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >$700</li>
                                    </ul>
                                </div>
                                <div className="px-6 ">
                                    <ul className="flex justify-between ">
                                        <li className="text-[#fff] w-[120px]  py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >9th</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >0x809...8a5af</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >2024-03-08</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >8</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >$100,942.26</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >Diamond</li>
                                        <li className="text-[#fff] w-[120px] text-end py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >$600</li>
                                    </ul>
                                </div>


                                <div className="px-6 ">
                                    <ul className="flex justify-between ">
                                        <li className="text-[#fff] w-[120px]  py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >10th</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >0xc13...aebc6</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >2024-03-08</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >6</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >$70,840.99</li>
                                        <li className="text-[#fff] w-[180px] text-center py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >Platinum</li>
                                        <li className="text-[#fff] w-[120px] text-end py-2 text-[18px] font-[400] leading-[28px] font-[inter]" >$500</li>
                                    </ul>
                                </div>
                            </>

                        }

                    </div>



                </div>

                {
                    !show &&
                    <div className="flex justify-center pt-[30px]">
                        <button onClick={handelShowMore} className="bgcolor text-[18px] font-[600] font-[inter] h-[64px] px-[44px] rounded-full">{t('BiggestSection.btn')}</button>
                    </div>
                }

            </div>
            <div className=" 2xl:hidden xl:hidden lg:hidden md:block sm:block block space-y-8">
                <div className="text-center space-y-5">
                    <h3 className="gradientText text-[30px] font-[900]">{t('BiggestSection.title')}</h3>
                    <p className="text-[18px] text-white">{t('BiggestSection.para')}</p>
                </div>
                <div className="Biggestbg px-[15px] py-[40px] space-y-3 2xl:hidden xl:hidden lg:hidden md:block sm:block hidblockace-y-3">
                    <div className="px-6">
                        <ul className="flex justify-between ">
                            <li className="text-[#F9C333] w-[120px] text-center text-[12px] font-[800] leading-[28px] font-[inter]" >{t('BiggestSection.rank')}</li>
                            <li className="text-[#F9C333] w-[180px] text-center  text-[12px] font-[800] leading-[28px] font-[inter]" >{t('BiggestSection.wallet')}</li>
                            <li className="text-[#F9C333] w-[180px] text-center  text-[12px] font-[800] leading-[28px] font-[inter]" >{t('BiggestSection.totalBuy')}</li>
                            <li className="text-[#F9C333] w-[120px] text-center  text-[12px] font-[800] leading-[28px] font-[inter]" >{t('BiggestSection.prize')}</li>
                        </ul>
                    </div>
                    <div className="px-6 tablerow">
                        <ul className="flex justify-between ">
                            <li className="text-[#FFF9AD] w-[120px]  py-2 text-[12px] font-[600] leading-[28px] font-[inter]" >1st</li>
                            <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[12px] font-[600] leading-[28px] font-[inter]" >0x0de...8e610</li>
                            <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[12px] font-[600] leading-[28px] font-[inter]" >$315,513.01</li>
                            <li className="text-[#FFF9AD] w-[120px] text-end py-2 text-[12px] font-[600] leading-[28px] font-[inter]" >$10,000</li>
                        </ul>
                    </div>
                    <div className="px-6 tablerow2">
                        <ul className="flex justify-between ">
                            <li className="text-[#FFF9AD] w-[120px]  py-2 text-[12px] font-[600] leading-[28px] font-[inter]" >2nd</li>
                            <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[12px] font-[600] leading-[28px] font-[inter]" >0x589...18e01</li>
                            <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[12px] font-[600] leading-[28px] font-[inter]" >$201,181.17</li>
                            <li className="text-[#FFF9AD] w-[120px] text-end py-2 text-[12px] font-[600] leading-[28px] font-[inter]" >$5,000</li>
                        </ul>
                    </div>
                    <div className="px-6 tabalerow3">
                        <ul className="flex justify-between ">
                            <li className="text-[#FFF9AD] w-[120px]  py-2 text-[12px] font-[600] leading-[28px] font-[inter]" >3rd</li>
                            <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[12px] font-[600] leading-[28px] font-[inter]" >0xe6e...32b4f</li>
                            <li className="text-[#FFF9AD] w-[180px] text-center py-2 text-[12px] font-[600] leading-[28px] font-[inter]" >$108,999.00</li>
                            <li className="text-[#FFF9AD] w-[120px] text-end py-2 text-[12px] font-[600] leading-[28px] font-[inter]" >$3,000</li>
                        </ul>
                    </div>
                    <div className="px-6 ">
                        <ul className="flex justify-between ">
                            <li className="text-[#fff] w-[120px]  py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >4th</li>
                            <li className="text-[#fff] w-[180px] text-center py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >0xcc6...85fda</li>
                            <li className="text-[#fff] w-[180px] text-center py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >$106,799.20</li>
                            <li className="text-[#fff] w-[120px] text-end py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >$2,000</li>
                        </ul>
                    </div>

                    <div className="px-6 ">
                        <ul className="flex justify-between ">
                            <li className="text-[#fff] w-[120px]  py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >5th</li>
                            <li className="text-[#fff] w-[180px] text-center py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >0xbbf...c627c</li>
                            <li className="text-[#fff] w-[180px] text-center py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >$105,349.88</li>
                            <li className="text-[#fff] w-[120px] text-end py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >$1500</li>
                        </ul>
                    </div>
                    {
                        show &&
                        <>

                            <div className="px-6 ">
                                <ul className="flex justify-between ">
                                    <li className="text-[#fff] w-[120px]  py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >6th</li>
                                    <li className="text-[#fff] w-[180px] text-center py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >0xcc6...85fda</li>
                                    <li className="text-[#fff] w-[180px] text-center py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >$105,120.76</li>
                                    <li className="text-[#fff] w-[120px] text-end py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >$900</li>
                                </ul>
                            </div>

                            <div className="px-6 ">
                                <ul className="flex justify-between ">
                                    <li className="text-[#fff] w-[120px]  py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >7th</li>
                                    <li className="text-[#fff] w-[180px] text-center py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >0x145...122eb</li>
                                    <li className="text-[#fff] w-[180px] text-center py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >$103,213.41</li>
                                    <li className="text-[#fff] w-[120px] text-end py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >$800</li>
                                </ul>
                            </div>

                            <div className="px-6 ">
                                <ul className="flex justify-between ">
                                    <li className="text-[#fff] w-[120px]  py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >8th</li>
                                    <li className="text-[#fff] w-[180px] text-center py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >0x815...7f652</li>
                                    <li className="text-[#fff] w-[180px] text-center py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >$103,160.51</li>
                                    <li className="text-[#fff] w-[120px] text-end py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >$700</li>
                                </ul>
                            </div>
                            <div className="px-6 ">
                                <ul className="flex justify-between ">
                                    <li className="text-[#fff] w-[120px]  py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >9th</li>
                                    <li className="text-[#fff] w-[180px] text-center py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >0x809...8a5af</li>
                                    <li className="text-[#fff] w-[180px] text-center py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >$100,942.26</li>
                                    <li className="text-[#fff] w-[120px] text-end py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >$600</li>
                                </ul>
                            </div>

                            <div className="px-6 ">
                                <ul className="flex justify-between ">
                                    <li className="text-[#fff] w-[120px]  py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >10th</li>
                                    <li className="text-[#fff] w-[180px] text-center py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >0xc13...aebc6</li>
                                    <li className="text-[#fff] w-[180px] text-center py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >$70,840.99</li>
                                    <li className="text-[#fff] w-[120px] text-end py-2 text-[12px] font-[400] leading-[28px] font-[inter]" >$500</li>
                                </ul>
                            </div>
                        </>

                    }

                </div>

                {
                    !show &&
                    <div className="flex justify-center ">
                        <button onClick={handelShowMore} className="bgcolor text-[18px] font-[600] font-[inter] h-[50px] px-[44px] rounded-full">{t('BiggestSection.btn')}</button>
                    </div>
                }

            </div>

        </div>
    )
}
export default BiggestSection 
